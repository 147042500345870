import { ApiPaginatedResponse, ApiSuccessResponse } from "./api-http-requests";
import { TherapySessionLanguage } from "./general";
import { PaymentIntentCurrency } from "./payment-intent";
import { TherapistUserPublicData } from "./user";

export enum ProductType {
    PRODUCT = "product",
    APPOINTMENT = "appointment",
    BUNDLE = "bundle",
    EXPERIMENTAL = "experimental"
}

export enum ProductSubtype {
    THERAPY_SESSION = "therapy_session"
}
export enum ProductAppointmentSubtype {
    THERAPY_SESSION = ProductSubtype.THERAPY_SESSION
}

export enum ProductExperimentalTestId {
    PROGRAM_BREAKING_CHANGE_30D = "program_breaking_change_30d"
}

export type ProductPrice = {
    unit_gross_price: number;
    vat_rate: number;
    vat_label: string;
    currency: PaymentIntentCurrency;
    old_price?: number;
    old_price_info?: string;
    l30d_lowest_price_info?: string;
};
export type ProductBundleItem = {
    product: ProductTypeProduct | ProductTypeAppointment;
    quantity: number;
};

export type ProductBase = {
    _id: string;
    name: string;
    price: ProductPrice;
    active: boolean;
    c_date: number;
    m_date: number;
    short_name?: string;
};
export type ProductTypeBundle = ProductBase & {
    type: ProductType.BUNDLE;
    bundle_items: ProductBundleItem[];
};

export type ProductTypeAppointment = ProductBase & {
    type: ProductType.APPOINTMENT;
    subtype: ProductAppointmentSubtype.THERAPY_SESSION;
    appointment: {
        duration: number;
        default_buffer_time_after: number;
        participant_count: number;
        therapy_session: {
            lang: TherapySessionLanguage;
            other_langs_equivalents: Partial<Record<TherapySessionLanguage, string>>;
        };
    };
};
export type ProductTypeProduct = ProductBase & {
    type: ProductType.PRODUCT;
};
export type ProductTypeExperimental = ProductBase & {
    type: ProductType.EXPERIMENTAL;
    experimental: {
        test_id: ProductExperimentalTestId;
    };
};

export type Product =
    | ProductTypeProduct
    | ProductTypeAppointment
    | ProductTypeBundle
    | ProductTypeExperimental;

export type ProductAvailabilitySlot = {
    slot_id: string;
    quantity: number;
    duration: string;
    from: string;
    to: string;
};

// #####################
// ### HTTP REQUESTS ###
// #####################
export type ApiProductsResponse<T = Product> = ApiPaginatedResponse & { products: T[] };
export type ApiProductResponse<T = Product> = ApiSuccessResponse & { product: T };

export type ApiProductsAvailabilitySlotsResponse = ApiSuccessResponse & {
    refresh_token: string;
    availability_slots: ProductAvailabilitySlot[];
};

export type ApiTherapistsByProductResponse = ApiPaginatedResponse & {
    therapists: TherapistUserPublicData[];
};
